import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Edit = _resolveComponent("Edit", true)!

  return (_ctx.userID)
    ? (_openBlock(), _createBlock(_component_Edit, {
        key: 0,
        id: _ctx.userID
      }, null, 8, ["id"]))
    : _createCommentVNode("", true)
}