
import { computed, defineComponent, onMounted } from "vue";

import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import Edit from "@/components/Edit.vue";

export default defineComponent({
  name: "me",
  components: {
    Edit,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Me");
    });
    const store = useStore();

    const userID = computed(() => {
      return store.getters.currentUser.id;
    });
    return {
      userID,
    };
  },
});
