
import { computed, defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import apiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "admin-edit",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  setup: function (props) {
    let withImage = ref(0);

    const store = useStore();
    const router = useRouter();
    const submitButton = ref<HTMLElement | null>(null);
    const busyStatus = ref("false");
    const date = ref("");
    const changePasswordData = ref({
      current_password: "",
      new_password: "",
      new_password_confirmation: "",
    });
    const userID = ref(props.id);
    let errorsRef = ref(null);
    let errorsPasswordRef = ref(null);
    const profileDetailsValidator = computed(() => {
      return validator();
    });
    const profileDetails = ref({
      avatar: "",
      first_name: "",
      last_name: "",
      username: "",
    });
    let file;
    const passwordFormDisplay = ref(false);
    const updatePasswordButton = ref<HTMLElement | null>(null);
    const changePassword = Yup.object().shape({
      currentpassword: Yup.string().required().label("Current password"),
      newpassword: Yup.string().min(8).required().label("Password"),
      confirmpassword: Yup.string()
        .min(8)
        .required()
        .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
        .label("Password Confirmation"),
    });
    const updatePassword = () => {
      let array = JSON.parse(JSON.stringify(errorsPasswordRef.value));
      let count = array ? Object.keys(array).length : 0;
      if (updatePasswordButton.value && count <= 0) {
        // Activate indicator
        updatePasswordButton.value?.setAttribute("disabled", "disabled");
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");
        apiService
          .post(`/${userID.value}/change/password`, changePasswordData.value)
          .then(({ data }) => {
            let button = document.getElementById("kt_password_cancel");
            button?.click();
            updatePasswordButton.value?.removeAttribute("disabled");
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");
            data?.success ? successPassword(data.success) : null;
          })
          .catch(function (error) {
            if (error.response.status == 401) {
              router.push({ name: "sign-in" });
            }
            updatePasswordButton.value?.removeAttribute("disabled");
            updatePasswordButton.value?.removeAttribute("data-kt-indicator");
            if (error.response.status == 422) {
              error.response?.data?.message
                ? setPasswordErrors({
                    current_password: [error.response?.data?.message],
                  })
                : null;
              error.response?.data?.errors
                ? setPasswordErrors(error?.response?.data?.errors)
                : null;
            }
          });
      }
    };

    function setPasswordErrors(data) {
      errorsPasswordRef.value = data;
     }

    function successPassword(text) {
      Swal.fire({
        text: text,
        icon: "success",
        confirmButtonText: "Ok",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-light-primary",
        },
      }).then(() => {
        passwordFormDisplay.value = false;
      });
    }

    function validator() {
      let validation;
      validation = {
        first_name: Yup.string().required().label("First name"),
        last_name: Yup.string().required().label("Last name"),
        username: Yup.string().required().min(6).label("username"),
      };
      return Yup.object().shape(validation);
    }

    const submit = () => {
      let array = JSON.parse(JSON.stringify(errorsRef.value));
      let count = array ? Object.keys(array).length : 0;
      if (!submitButton.value || count > 0) {
        return;
      }
      submitButton.value.setAttribute("disabled", "disabled");

      submitButton.value.setAttribute("data-kt-indicator", "on");
      let formData = new FormData();
      let json = JSON.parse(JSON.stringify(profileDetails.value));
      formData.append("first_name", json?.first_name);
      formData.append("last_name", json?.last_name);
      formData.append("username", json?.username);
      formData.append("withImage", `${withImage.value}`);
      file ? formData.append("avatar", file) : null;

      apiService
        .post(`/${userID.value}`, formData)
        .then(() => {
          store.dispatch(Actions.ME);
          withImage.value = 0;
          success();
          file = null;
        })
        .catch(function (error) {
          if (submitButton.value) {
            submitButton.value?.removeAttribute("data-kt-indicator");
            submitButton.value?.removeAttribute("disabled");
          }
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
          if (error.response && error.response.status == 422) {
            getError(error.response?.data.errors);
            file = null;
          }
        });
    };

    function getError(error) {
      errorsRef.value = error;
      if (submitButton.value) {
        submitButton.value?.removeAttribute("data-kt-indicator");
        submitButton.value?.removeAttribute("disabled");
      }
    }

    function success() {
      if (submitButton.value) {
        submitButton.value?.removeAttribute("data-kt-indicator");
        submitButton.value?.removeAttribute("disabled");
      }
      Swal.fire({
        text: `The Admin has been successfully updated!`,
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    }

    const getUser = () => {
      apiService
        .get(`/${userID.value}`)
        .then(({ data }) => {
          if (data?.data) {
            profileDetails.value = data?.data;
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    };
    const changeAvatar = (event) => {
      withImage.value = 1;
      hideError("avatar");
      profileDetails.value.avatar = URL.createObjectURL(event.target.files[0]);
       file = event.target.files[0];
    };
    const hideError = (key) => {
      if (key && errorsRef.value) {
        let array = JSON.parse(JSON.stringify(errorsRef.value));
        delete array[key];
        errorsRef.value = new Proxy(array, {});
      }
    };
    const hidePasswordError = (key) => {
      if (key && errorsPasswordRef.value) {
        let array = JSON.parse(JSON.stringify(errorsPasswordRef.value));
        delete array[key];
        errorsPasswordRef.value = new Proxy(array, {});
      }
    };
    const removeImage = () => {
      withImage.value = 1;
      profileDetails.value.avatar = "media/avatars/blank.png";
      file = null;
    };

    onMounted(() => {
      getUser();
    });
    return {
      submitButton,
      submit,
      getUser,
      profileDetails,
      profileDetailsValidator,
      busyStatus,
      date,
      changeAvatar,
      errorsRef,
      removeImage,
      passwordFormDisplay,
      updatePasswordButton,
      changePassword,
      updatePassword,
      changePasswordData,
      errorsPasswordRef,
      hidePasswordError,
      hideError,
    };
  },
});
